import React from 'react';
import Icon from '../Icon';

function ArrowRight(props) {
  return (
    <Icon {...props}>
      <path
        d="M8.4 14.667l6.933-7.467-2-1.867-8.933 9.733c-0.533 0.533-0.533 1.333 0 1.867l8.933 9.733 2-1.867-6.933-7.467h19.6v-2.667h-19.6z"
        transform="rotate(180 16 16)"
      />
    </Icon>
  );
}

export default ArrowRight;
